import { RedocStandalone } from "redoc";

export default function App() {
  return (
    <div className="App">
      <RedocStandalone
        specUrl={
          process.env.NODE_ENV === "development"
            ? "v2/api.json"
            : "api-service/v2/api.json"
        }
        options={{
          hideLoading: true,
          disableSearch: true,
          jsonSampleExpandLevel: 3,
          theme: {
            typography: {
              fontSize: "16px",
              lineHeight: "1.5",
              headings: {
                fontFamily: "Manrope,sans-serif",
              },
            },
            sidebar: {
              backgroundColor: "white",
              activeTextColor: "#1f2f47",
            },
            rightPanel: {
              backgroundColor: "#1f2f47",
            },
          },
        }}
      />
    </div>
  );
}
